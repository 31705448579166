@import '../../styles/colors.scss';

.base {
  .header {
    display: block;
    background: $color-cloud;
    .content {
      padding: 0 2.5rem 8rem;
    }
  }

  .body {
    font-family: "Optum Sans";
    // padding: 1rem 2.5rem 4rem;
    min-height: 70vh;
  }
}
