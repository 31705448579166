@import '../../../styles/colors.scss';
 .headerDiv{
  font-family: "Optum Sans";
  width: auto;
  // height: 81px;
  padding-bottom: 2%;
  gap: 24px;
  border-bottom: 2px solid #CBCCCD;
  margin-top: 1%;
  .subHead{
    width: auto;
    // height: 56px;
    gap: 16px;
    border: 0px 0px 1px 0px;
    display: flex;
    .headerFrameWhite{
      padding: 12px 6px 0px 6px;
      border-radius:4px;
      // margin: auto;
      height: 40px;
      img{
        height: 20px; width:20px; margin-bottom: -3px;
      }
      span{
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        padding: 6px;
      }
      }
    .headerFrame{
      cursor: pointer;
      padding: 12px 6px 0px 6px;
      background: #F3F3F3;
      border-radius:4px;
      // margin: auto;
      height: 40px;
      img{
        height: 20px; width:20px; margin-bottom: -3px;
      }
      span{
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        padding: 6px;
      }
      }
      .iconFrame{
        cursor: pointer;
        padding: 16px;
        gap: 8px;
        img{
          height: 30px; width:30px; margin-bottom: -3px;
        }
        
        }
      
  }
  .compareGraphDiv{
  display: block;
  margin-top: 2%;
   .flexDiv{display: flex;
    .titleDiv{width: 50%;}
  .filterDiv{
    width:50%;
    display:flex;
    .selectDiv{
      min-width: 50%;
      max-width: 50%;
      margin-bottom: 24px;
      border-radius: 4px;
      box-sizing: border-box;
      margin-right: 1.5%;
      margin-top: 1px;
      cursor: pointer;
      span{
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  }
  
  }
  .compareGraphDivHide
  {
    display: none;
  }
  .captionFormat {
    font-family: 'Optum Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20.25px;
    line-height: 24px;
    color: #002677;
    //margin-left: 10px;
    //margin-top: 2%;
    margin-bottom: 2%;
  }

  
 }
 .pageHeader {
  width: 100%;
  display: flex;
}
.pageHeaderTextUHC {
  display: flex;
  padding: 1rem 1rem;
  // width: 10%;
  color: rgb(0, 38, 119);
  font-style: normal;
  h5{    font-family: "Optum Sans";
    font-size: 22px;
    font-weight: 600;

    }
  }
.pageHeaderText {
  display: flex;
  padding: 1rem 1rem;
  // width: 10%;
  color: rgb(0, 38, 119);
  font-style: normal;
  h5{    font-family: "Optum Sans";
    font-size: 22px;
    font-weight: 600;
    padding-top: 20px;
    }
    p{ font-family: "Optum Sans";
      font-size: 14px;
      font-weight: 400;

    }
    .iconFrame{
      cursor: pointer;
      padding: 16px;
      gap: 8px;
      img{
        height: 24px; width:24px; margin-bottom: -3px;
      }
      
      }
      .iconFrameHide{display: none;}
}


.dataExport {
  width: 40%;
  height: 35px;
  padding: 1rem 1rem;
  display: flex;
  font-size: 14px;
  padding-top:30px;
  flex-direction: row-reverse;
  .button{
    cursor: pointer;
    width: 60px;
    padding: 10px;
    border-radius: 999px;
    border: 1px solid #002677;
    font-family: Optum Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    color:  #002677;
  }
}
.dataFilter {
  width: 50%;
  padding: 1rem 1rem;
  display: flex;
  font-size: 14px;
  .filterOption{
    padding-right: 16px;
    width: 25%;
    color: #4b4d4f;
    span{
      font-size: 16px;
      font-weight: 700;
    }
  }
  .filterOption2{
    
    padding-right: 1%;
    width: 50%;
  }
}
.dateWeek {
  width: 60%;
  margin-bottom: 16px;
  .filterOption{
    width: 30%;
  }
  .position {
    padding: 0;
    margin: 0;
    opacity: 0.6;
    text-transform: uppercase;
  }

  .positionButton {
    background-color: #fff;
    padding: 2px 6px;
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
    border: 0px;
    text-transform: uppercase;
  }
  .displayDateMargin {
     padding-left: 50px;
    text-align: left;
    font-family: 'Optum Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #002677;
    // margin-top: 20px;
  }

  .setHeight {
    height: 1px; // Not required to show this section on UI
  }
  .forecastedData {
    height: 14px;
    /* left: 92.8%; */
    /* right: 0.61%; */
    top: calc(50% - 14px / 2 - 40.5px);
    font-family: 'Optum Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #424242;
    margin-left: 20px;
  }
  .claimsData {
    height: 14px;
    left: 81.74%;
    right: 13.33%;
    top: calc(50% - 14px / 2 - 40.5px);
    font-family: 'Optum Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #424242;
    margin-left: 280px;
  }
}
.topHeader{
  width: 100%;
  display: flex;
}
.topHeaderTitle {
  padding: 10px 10px;
  width: 60%;
  font-style: normal;
  font-family: "Optum Sans";
    font-size: 14px;
    font-weight: 400;
}

.topHeaderTab{
  width: 40%;
  padding: 0px 10px;
  display: flex;
  font-family: "Optum Sans";
  font-size: 14px;
  flex-direction: row-reverse;

}
.headerTab{
  padding: 5px 10px 5px 10px;
      
      img{
        height: 24px; width:24px; margin-bottom: -3px;
      }
      span{margin-left: 10px;}
}
.graphSourceDiv{
  display: flex;
  flex-direction: row-reverse;
.graphSource {
  font-size: 12px;
  padding-left: 10px;
  Span {
    font-size: 12px;
    font-weight: 600;
  }
}
}
