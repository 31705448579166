@import '../../../styles/colors.scss';
 .chartDiv{
  font-family: "Optum Sans";
  width: auto;
  padding: 2%;
  gap: 24px;
  border: 1px solid #CBCCCD;
  margin-top: 1%;
  .subHead{
    width: auto;
    height: 56px;
    gap: 16px;
    border: 0px 0px 1px 0px;
    opacity: 0px;
    display: flex;
    .headerFrameWhite{
      padding: 16px;
      gap: 8px;
      border-radius:4px;
      img{
        height: 24px; width:24px; margin-bottom: -3px;
      }
      span{
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        padding: 10px;
      }
      }
    .headerFrame{
      cursor: pointer;
      padding: 16px;
      gap: 8px;
      background: #F3F3F3;
      border-radius:4px;
      img{
        height: 24px; width:24px; margin-bottom: -3px;
      }
      span{
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        padding: 10px;
      }
      }
      .iconFrame{
        cursor: pointer;
        padding: 16px;
        gap: 8px;
        img{
          height: 24px; width:24px; margin-bottom: -3px;
        }
        
        }
      
  }
  .compareGraphDiv{
  display: block;
  margin-top: 2%;
  }
  .compareGraphDivHide
  {
    display: none;
  }
  .captionFormat {
    font-family: 'Optum Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20.25px;
    line-height: 24px;
    color: #002677;
    //margin-left: 10px;
    //margin-top: 2%;
    margin-bottom: 2%;
  }
  .subCaptionFormat {
    font-family: 'Optum Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

  }

  
 }
