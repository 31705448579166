@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 24px;
  grid-template-areas:
    'full-timeline-panel'
    'date-panel'
    'filter-panel'
    'map'
    'cbsas-panel'
    'accuracy-panel'
    'timeline-panel'
    'genderage-panel';

  .map {
    grid-area: map;

    > svg {
      position: absolute;
      background: #fff;
      z-index: 1;
      margin-top: 16px;
      margin-left: 16px;
    }
    .mapZoomToggle{
      // position: absolute;
      width: 140px;
      background: #fff;
      display: flex;
      z-index: 1;
      padding: 10px;
      border: 1px solid #CBCCCD;
      border-radius: 5px;

      >span{
        font-size: 14px;
        margin-right: 15px;
      }
    }
    .time {
      position: absolute;
      background: #fff;
      z-index: 1;

      margin-top: -8%;
      margin-left: 4%;
      width: 65%;
      height: 100px;
    }
  }

  .cbsasPanel {
    grid-area: cbsas-panel;
  }

  .datePanel {
    grid-area: date-panel;
  }

  .timelinePanel {
    grid-area: timeline-panel;
  }
  .genderagepanel {
    grid-area: genderage-panel;
  }
  .accuracyPanel {
    grid-area: accuracy-panel;
  }
  .fullTimelinePanel{
    grid-area: full-timeline-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    // margin-top: 2rem;
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      'full-timeline-panel full-timeline-panel'
      'date-panel date-panel'
      'cbsas-panel map'
      'timeline-panel timeline-panel'
      'genderage-panel genderage-panel';

    .map {
      min-height: 90vh;
      border-top: 1.5px solid #cbcccd;
      border-right: 1.5px solid #cbcccd;
      border-bottom: 1.5px solid #cbcccd;
      border-left: 1.5px solid #cbcccd;
      border-radius: 0px 4px 0px 0px;
      margin-left: -24px;
      margin-top: -48px;
    }
    .fullTimelinePanel{
      border: 1.5px solid #cbcccd;
    }
    .cbsasPanel{ margin-top: -48px;}
    .datePanel {
      margin-top: -25px;
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22.78px;
        line-height: 30px;
        color: #424242;
      }
      
    }
    .timelinePanel {
      grid-area: timeline-panel;
      background-color: #fff;
      border-top: 1.5px solid #cbcccd;
    }
    .providerHeader {
      border-bottom: 1.5px solid #cbcccd;
      height: 90px;
    }
    .filterHeader {
      border-bottom: 1.5px solid #cbcccd;
      height: 60px;
    }
    .mapPopup {
      width: 54vh;
      margin-left: 30%;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
      background-color: white;
      box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
    }
  }
}
