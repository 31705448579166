@import './../../../styles/colors.scss';

.base {
  display: grid;
  margin-top: -48px;

  grid-template-areas: 'genderage-panel';

  .genderPanel {
    grid-area: genderage-panel;
  }
}

.genderMale {
  width: 16px;
  height: 16px;
  //margin-left: 10px;
  top: 335.05px;
  background: #15a796;
  border-radius: 4px;
  margin-bottom: 0.5%;
}

.genderFemale {
  width: 16px;
  height: 16px;
  //margin-left: 10px;
  top: 361.05px;
  background: #c71e83;
  border-radius: 4px;
  margin-bottom: 1%;
}
.captionFormat {
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.25px;
  line-height: 24px;
  color: #002677;
  //margin-left: 5px;
}
.subCaptionFormat {
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4b4d4f;
  margin-top: 8px;
}
.textFormat {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: #4b4d4f;
  margin-left: 18px;
  text-align: center;
}
.maindivFormat {
  border: 1.5px solid #cbcccd;
  border-radius: 4px;
  padding: 32px 24px 32px 24px;
  // padding-top: 32px;
  //padding-left: 24px;
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 12fr;
    grid-template-areas: 'gender-panel';
    font-size: 12px;

    .genderPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      box-shadow: 0 0 4px 0 #888888;
      Span {
        font-size: 18px;
        padding-left: 5px;
        Span {
          font-size: 12px;
          padding-left: 5px;
        }
      }
    }
  }
}
