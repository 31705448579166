@import '../../styles/colors.scss';

.base {
  display: grid;

  border: 1.5px solid #cbcccd;
  height: 90vh;
  display: flex;
  flex-direction: column;
  border-radius: 4px 0px 0px 4px;
  // margin-top: -1.6%;
}
.showOnMap {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
  text-align: right;
  margin-right: 20px;
}
.searchInput{
  outline: 0;
  border: 1;
  transition: all 0.2s ease 0s;
  font-size: 16px;   
  color: #4b4d4f;
  height: 2.5rem;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  border-width: 0 0 2px;
  border-bottom:2px solid rgb(0, 38, 119)
}
.headerDiv{
  display: flex;
  .captionFormat {
    width: 70%;
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.25px;
  line-height: 24px;
  color: #002677;
  //margin-left: 5px;
}
.sideFilter{ width: 30%;display: flex;
  justify-content: flex-end;
.selectList{
width: 30%;
  margin-bottom: 24px;
  border-radius: 4px;
  box-sizing:  border-box;
  margin-right: 1.5%;
  margin-top: 1px;
  cursor: pointer;
}

.searchBox{   
  width: 70%;
  margin-bottom: 24px;
  border-radius: 4px;
  
  box-sizing:  border-box;
  margin-right: 1.5%;
  margin-top: 1px;
  cursor: pointer;}  
}
.subCaptionFormat {
  display: flex;
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4b4d4f;
  margin-top: 8px;
  a{text-decoration: underline;}
  
}
.textFormat {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: #4b4d4f;
  margin-left: 18px;
  text-align: center;
}

}
.dataCount{
  gap: 64px;
  font-family: 'Optum Sans';
  width: 60%;
  display: flex;
  margin: 0px auto;
  text-align: center;
  .displayCount{
    gap: 4px;
    span:nth-of-type(1) {
     color: #4B4D4F;
     font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }
    span:nth-of-type(2) {
      color: #002677;
    
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }
  

  }

}
.maindivFormat {
  font-family: 'Optum Sans' ,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  overflow: scroll;
  height: 90vh;

  > table{
    border-collapse: collapse;
    width:100%;
    > thead{text-align: left;}
    >thead>tr{border-bottom: 2px solid #002677;border-top: 1px solid #ddd;white-space:nowrap;}
    >thead>tr>th{height: 40px;gap:4px;padding: 10px;vertical-align: middle;
    position:sticky;top: 0;background-color:#FFFFFF ;}
    >tbody>tr>td{
      height: 40px;
      gap:4px;
      padding: 0px 10px 0px 10px;
      vertical-align: middle;
      white-space:nowrap;
      >img{
        padding-right: 5px;
      }
    }

   
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 12fr;
    grid-template-areas: '';
    font-size: 12px;
 

  }
}
