@import '../../styles/colors.scss';

.base {
  color: #424242; //$color-blue-1;
  display: flex;
  flex-direction: row;
  //overflow: hidden;
  //margin-left: 650px;
  // margin-top: 24px;
  .icon {
    i {
      vertical-align: middle;
    }
  }
  .dataFilter {
    width: 40%;
    flex-direction: row-reverse;
    padding: 1rem 1rem;
    // margin-bottom: 16px;
    display: flex;
    font-size: 14px;
    .filterOption{
      padding-right: 1%;
      width: 50%;
    }
    .filterOption2{
      padding-right: 16px;
      width: 50%;
      span{
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  .dateWeek {
    width: 70%;
    padding: 2rem 1rem;

    .filterOption{
      width: 30%;
    }
    .position {
      padding: 0;
      margin: 0;
      opacity: 0.6;
      text-transform: uppercase;
    }

    .positionButton {
      background-color: #fff;
      padding: 2px 6px;
      border-radius: 4px;
      cursor: pointer;
      opacity: 1;
      border: 0px;
      text-transform: uppercase;
    }
    .displayDateMargin {
       padding-left: 20px;
      text-align: left;
      font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 19px;
      color: #002677;
       margin-top: 10px;
    }

    .setHeight {
      height: 1px; // Not required to show this section on UI
    }
    .forecastedData {
      height: 14px;
      /* left: 92.8%; */
      /* right: 0.61%; */
      top: calc(50% - 14px / 2 - 40.5px);
      font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #424242;
      margin-left: 20px;
    }
    .claimsData {
      height: 14px;
      left: 81.74%;
      right: 13.33%;
      top: calc(50% - 14px / 2 - 40.5px);
      font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #424242;
      margin-left: 280px;
    }
  }
}
